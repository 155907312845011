import React, { Component } from 'react';

import Panotop from './PanotopComp';

import nodeUtils from '../js/nodeUtils.js'



class Arskan2comp extends Component {
  constructor(props) {
    super(props);


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.toggleFullscreen = this.toggleFullscreen.bind(this);

    this.arskannb = "https://viewer.arskan.com/objects/share/626a8e89779c85210af98be2"

    this.modelename = this.props.match.params.modelname;

    this.refnumber = "";
    this.title = "Wrong url";
    this.details = "Select HD in tool bar for best quality";

    if (this.modelename == "caliber") {
      this.refnumber = "c96491c814b44dc988c535117182f5c9"
      this.title = "Watch caliber Macro Photogrammetry"
      this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }
   if (this.modelename == "goliath") {
      this.refnumber = "140fbd9f281647fb8637acf90471a23f"
      this.title = "Goliath - Christophe Charbonnel"
      this.details = "Select HD in tool bar for best quality";
    }
    if (this.modelename == "ballondor") {
      this.refnumber = "6a907ccda9744851968a51de0bd755fa"
      this.title = "Ballon d'or Maradona 1986"
      this.details = "Select HD in tool bar for best quality";
    }
    if (this.modelename == "goldenball") {
      this.refnumber = "9c48b162167c42b99170c52093ad06fb"
      this.title = "Golden Ball 1986"
      this.details = "";
    }
    if (this.modelename == "bugattiright") {
      this.refnumber = "812db7b460af40fa91765bd955b75d0d"
      this.title = "Bugatti Type 30 - Quick draft"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }
    if (this.modelename == "leo2") {
      this.refnumber = "3ff89c13900745f8a0edf63bb9689726"
      this.title = "Leo Caillard"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }
    if (this.modelename == "rouault_nativite") {
      this.refnumber = "60b2543b73d9499c8c87c01266c4f31e"
      this.title = "Georges Rouault - Nativité"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }        
    if (this.modelename == "rouault_sarah") {
      this.refnumber = "ccd50276ec744a62abc6af9b27ca7f0c"
      this.title = "Georges Rouault - Sarah"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }    
    if (this.modelename == "mammouth") {
      this.refnumber = "bbbe71e850424885877caa6171af29b0"
      this.title = "Mammouth"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }    
    if (this.modelename == "Ckge7cQuqo6c") {
      this.refnumber = "bbbe71e850424885877caa6171af29b0"
      this.title = "Mammouth"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }  
    if (this.modelename == "lion") {
      this.refnumber = "e6345791becd497b83d2b57bf686043d"
      this.title = "British Museum Lion"
      // this.details = "This is a draft 3D model of an actual 30mm wide Universal watch caliber";
    }
    if (this.modelename == "apocalypse") {
      this.refnumber = "ff1d0c1c2ac1444ba34fc4e1716c4cea"
      this.title = "Tapisserie de l'Apocalypse - Angers"
      this.details = "Clicker sur HD dans la barre d'outil (peut ne pas fonctionner sur iphone). Selectionner une étiquette pour se positionner devant la tapisserie";
    }
    this.url = "https://sketchfab.com/models/"+ this.refnumber+ "/embed?ui_infos=0&autostart=1&ui_watermark_link=0&ui_watermark=0"

    // this.url ="https://sketchfab.com/models/d2fff37753ea40cfaf5562f8006a4af9/embed?ui_infos=0&autostart=1&ui_watermark_link=0&ui_watermark=0"

    console.log(this.url);

    this.modelename = this.props.match.params.name;

     this.state = {
       url:this.url,
       title:this.title,
       details:this.details
     }
      
    // }; 

  }
  
  componentDidMount() {
    
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")

    // console.log("trad",)

    // console.log("trad",nodeUtils.translateText(this.state.texttosend,'en'))

    // nodeUtils.translateText(this.state.texttosend,'en').then((value) => console.log("trad",value));
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"avatarsay","content":this.state.texttosend});
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"hideavatar"});
  }

  button2(){
    console.log("bouton1")
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"showavatar"});
  }

  handleSubmit(event) {
    // alert('Le nom a été soumis : ' + this.state.value);
    console.log("event.target.value",event.target.value)
    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":event.target.value});
  }

  handleChange(event) {
    // console.log("tt" )
    // console.log(event.target.value )
    console.log('this.state.texttosend',this.state.texttosend)
    if (event.target.value != this.state.texttosend) {
      // console.log("hh")
      // this.setState({texttosend: event.target.value});
      this.state.texttosend = event.target.value
    }  
  }
 

  render() {

    const { url,title,details } = this.state;

   
    return (
      <div className="arksan blackBackground" >

      
{/* <iframe width="100%" height="100%" title="Universal Watch caliber Macro Photogrammetry" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/d2fff37753ea40cfaf5562f8006a4af9/embed"> </iframe> */}
{/* <iframe width="100%" height="100%" title="Universal Watch caliber Macro Photogrammetry" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/d2fff37753ea40cfaf5562f8006a4af9/embed"> </iframe> */}
<iframe width="100%" height="100%" title="Universal Watch caliber Macro Photogrammetry" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src={url}> </iframe>

{/* <iframe width="100%" height="100%" title="Universal Watch caliber Macro Photogrammetry" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/d2fff37753ea40cfaf5562f8006a4af9/embed?autospin=1&autostart=1&ui_watermark_link=0&ui_watermark=0&ui_help=0&ui_settings=0"> </iframe> */}

{/* <iframe width="100%" height="100%" src={this.arskannb} allowfullscreen></iframe> */}

        <div className="d-flex infront logotop notclickeable" >
            <img className="logotopright clickeable infront" src={'https://data2.gigascope.net/data/dtwin_logo_512_white.png'} alt="Logo" />   
            <div className="arksantitre notclickeable">
              <div>{title}</div>
              <div className="arksansoustitre">{details}</div>
              {/* <div className="arksansoustitre">select HD in the toolbox to get high res textures</div> */}
            </div>
        </div>
        
      </div>
      
    );
  }
}

export default Arskan2comp;
